import {fetchWrapper} from "../../helpers/fetch.wrapper";
const baseUrl = process.env.REACT_APP_BASE_URL;
export const userProfileService = {
    getToken,
    customerInfo,
    updateEmail,
    updatePassword,
    updateProfile,
    saveAddress,
    updateAddress,
    getCustomerAddress,
    deleteAddress,
    getLoyaltyNumber,
    updateStore,
    deleteProfile
}

async function getToken(loginDetail) {
    return fetchWrapper.post(`${baseUrl}/oauth2/token`,
        loginDetail)
        .then(response => {
            return response;
        });
}

async function saveAddress(addressDetails) {
    return fetchWrapper.post(`${baseUrl}/customer/address`,
        addressDetails)
        .then(response => {
            return response;
        });
}

async function customerInfo() {

    return fetchWrapper.get(`${baseUrl}/customer`
    )
        .then(response => {
            return response;
        });
}

async function getCustomerAddress() {

    return fetchWrapper.get(`${baseUrl}/customer/address`,
    )
        .then(response => {
            return response;
        });
}

async function updateEmail(emailDetails) {

    return fetchWrapper.put(`${baseUrl}/customer/email`,
        emailDetails)
        .then(response => {
            return response;
        });
}

async function updateAddress(addressDetails, id) {

    return fetchWrapper.put(`${baseUrl}/customer/address/${id}`,
        addressDetails)
        .then(response => {
            return response;
        });
}

async function updatePassword(passwordDetails) {

    return fetchWrapper.put(`${baseUrl}/customer/password`,
        passwordDetails)
        .then(response => {
            return response;
        });

}

async function updateProfile(profileDetails, email) {

    return fetchWrapper.put(`${baseUrl}/customer/profile/${email}`,
        profileDetails)
        .then(response => {
            return response;
        });

}

async function deleteProfile() {

    return fetchWrapper.post(`${baseUrl}/customer/delete/profile`,{})
        .then(response => {
            return response;
        });

}

async function updateStore(storeInfo, email) {

    return fetchWrapper.put(`${baseUrl}/customer/update-store/${email}`,
        storeInfo)
        .then(response => {
            return response;
        });

}

async function deleteAddress(id) {

    return fetchWrapper.delete(`${baseUrl}/customer/address/${id}`
    )
        .then(response => {
            return response;
        });

}

async function getLoyaltyNumber(phoneNumber, customerId) {

    return fetchWrapper.get(`${baseUrl}/inteq/loyalty/${phoneNumber}/${customerId}`,
    )
        .then(response => {
            return response;
        });
}
