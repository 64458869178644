import {hasAuthParams, useAuth} from "react-oidc-context";
import React, {useEffect, useState} from "react";
import Spinner from "./helpers/spinner";
import "./helpers/spinner.css";
import {isPrivateRoute} from "./utils/private-paths";

function AuthorizedComponent(props) {
    const auth = useAuth();
    const {children} = props;
    const [hasTriedSignin, setHasTriedSignin] = useState(false);

    const location = window.location.href;

    useEffect(() => {
        if ((!(hasAuthParams() || auth.isAuthenticated || auth.activeNavigator || auth.isLoading || hasTriedSignin)) && isPrivateRoute(location)) {
            void auth.signinRedirect();
            setHasTriedSignin(true);
        }
    }, [auth, hasTriedSignin, location]);

    if (!isPrivateRoute(location)) {
        return (
            <>
                {children}
            </>
        );
    }

    if (auth.isLoading) {
        return (
            <div className="pos-center">
                <Spinner/>
            </div>
        );
    }

    return (
        <>
            {auth.error ? (
                <>
                    <h1>Please try again later!</h1>
                </>
            ) : auth.isLoading ? (
                <>
                    <h1>Loading...</h1>
                </>
            ) : auth.isAuthenticated ? (

                <>{children}</>
            ) : (
                <>
                    <h1>Please try login again!</h1>

                </>
            )}
        </>
    );
};
export default AuthorizedComponent;
