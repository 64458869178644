import React, {useState} from "react"
import './App.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import "./helpers/spinner.css";
import {Route} from "react-router-dom";
import RegistrationPage from "./pages/RegistrationPage";
import AdminUpdatePage from "./pages/AdminUpdatePage";


function PublicRoutes() {

    const [alert, setAlert] = useState(null);

    const showAlert = (message, type) => {
        setAlert(({
            msg: message,
            type: type
        }))
        setTimeout(() => {
            setAlert(null);
        }, 4000)
    }

    const props = {alert, showAlert};

    return (

        <>
            <Route
                path="/register"
                render={() => <RegistrationPage {...props}/>}
            />
            <Route
                path="/admin/update/profile"
                render={() => <AdminUpdatePage {...props}/>}
            />
        </>

    )
}

export default PublicRoutes;
