import React, {useState} from 'react'
import Select from "react-dropdown-select";
import ftmlogo from '../../assets/images/fresh-thyme-market-logo.png';
import {useEffect} from "react";
import Alert from "../../helpers/alert";
import {SummaryModal} from "../modals/SummaryModal";
import {authService} from "../../service/auth/auth.service";
import {getHandleBlur} from "../../utils/dateValidator";


export const AdminUpdate = (props) => {
    const {
        handleSubmit,
        handleChange,
        error,
        stores,
        changeStoreLocation,
        showSummary,
        registering,
        alert,
        lookingUp,
        setLookupData,
        lookupError,
        handleLookup,
        lookUpResponse,
        user,
        handleSetValues,
        handleSummaryClose,
        autoSet,
        handleLogin,
        login,
        setCredentials,
        loginError,
        credentials,
        setUser,
        showAlert,
        setLookUpResponse,
        userName,
        setUserName
    } = props;

    const [activeTab, setActiveTab] = useState('table');

    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
    };
    const showDetails = {comparisonDetails: lookUpResponse?.meta?.comparisonSummary, showSummary, handleSummaryClose};
    const [defaultStore, setDefaultStore] = useState();
    const storeNumber = user?.storeNumber;
    const [logout, setLogout] = useState(false);
    const [lookupFields] = useState([
        {
            "id": "email",
            "label": "Email"
        },
        {
            "id": "loyaltyId",
            "label": "Loyalty Id"
        },
        {
            "id": "phoneNo",
            "label": "Phone Number"
        }
    ]);
    useEffect(() => {
        if (storeNumber && stores.length > 0) {
            setDefaultStore(stores.find(store => store.storeNumber?.toString() === storeNumber?.toString()));
            if (!autoSet) {
                changeStoreLocation({
                    target: {
                        name: "storeLocation",
                        value: defaultStore?.originalEcomName
                    }
                }, defaultStore?.storeNumber)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [storeNumber, stores.length]);


    function handleStoreChange(values) {
        changeStoreLocation({
            target: {
                name: "storeLocation",
                value: values[0].originalEcomName
            }
        });
        changeStoreLocation({
            target: {
                name: "storeNumber",
                value: values[0].storeNumber
            }
        });
    }

    function handleFieldChange(values) {
        setLookupData((oldLookupData) => {
            oldLookupData.fieldType = values[0].id;
            return oldLookupData;
        });

    }

    function handleSearchValueChange(e) {
        e.persist();
        setUser({
            ...user, ...{
                firstName: '',
                lastName: '',
                email: '',
                phoneNo: '',
                storeLocation: '',
                storeNumber: '',
                customerId: '',
                birthday: ''
            }
        });

        setLookupData((oldLookupData) => {
            oldLookupData.searchValue = e.target.value;
            return oldLookupData;
        });
    }

    function handleCredentialsValueChange(e) {
        setCredentials({
            ...credentials, [e.target.name]: e.target.value
        });
    }

    const handleUpdateWynshopLoyaltyId = (customerId) => {
        authService.updateWynshopLoyaltyId(customerId).then(data => {
                if (data && !data?.error) {
                    lookUpResponse.meta.wynshopCustomer.loyaltyId = lookUpResponse?.meta?.wynshopCustomer?.loyaltyId.substring(0, 11);
                    showAlert('Updated loyaltyId successful!', "success");
                } else {
                    showAlert(data?.error, "danger")
                }
            }, error => {
                showAlert(error, "danger");
            }
        );
    }

    const handleDeleteAccount = (e) => {
        e.preventDefault();
        authService.deleteCustomerRecord(user?.email).then(data => {
                if (data && !data?.error) {
                    showAlert(`Deleted ${user?.email} successfully!`, "success");
                    setLookUpResponse({});
                    // reloadPage('admin/update/profile');
                } else {
                    showAlert(data?.error, "danger")
                }
            }, error => {
                showAlert(error, "danger");
            }
        );
    }

    const handleBlur = (event) => {
        if (event.target.value.length < 11) {
            const value = getHandleBlur(event);
            if (value) {
                handleChange({target: {name: 'birthday', value: value}})
            }
        }
    }

    const handleLogoutAccount = (e) => {
        e.preventDefault();
        setLogout(true);
        localStorage.removeItem('basicauth');
        setUserName(null)
        setLogout(false);
        showAlert(`Logged out successfully!`, "success");
    }
    return (
        <div className="text-center m-6-auto">
            {userName &&
                <div style={{textAlign: "left"}}>
                    <div>Logged as: {userName}</div>
                    <button
                        type="submit"
                        style={{
                            backgroundColor: "#FFD700",
                            color: "#000",
                            height: "25px",
                            fontSize: "1em",
                            marginTop: "3px",
                            border: "none",
                            borderRadius: "5px",
                            cursor: "pointer",
                            padding: "0 8px",
                            display: "flex",
                            alignItems: "center",
                            gap: "5px"
                        }}
                        onClick={handleLogoutAccount}
                        disabled={logout}
                    >
                        Logout
                        {logout && <i className="fa fa-spinner fa-spin"></i>}
                    </button>
                </div>
            }

            {!userName ?
                <form>
                    <Alert alert={alert}/>
                    <h4 id="sub_head" style={{fontFamily: "A Love of Thunder", color: "#007935"}}>Login Panel</h4>
                    <div className="col-12">
                        <div className="form-group">
                            <input
                                type="text"
                                name="username"
                                onChange={handleCredentialsValueChange}
                                className="form-control"
                                placeholder="Enter username"
                                id="username"
                            />
                            <div className="text-danger">{loginError.username}</div>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="form-group">
                            <input
                                type="text"
                                name="password"
                                onChange={handleCredentialsValueChange}
                                className="form-control"
                                placeholder="Enter password"
                                id="password"
                            />
                            <div className="text-danger">{loginError.password}</div>
                        </div>
                    </div>
                    <button id="sub_btn" type="submit"
                            style={{backgroundColor: "#007935", height: "35px", fontSize: "1.2em"}}
                            onClick={handleLogin}
                            disabled={login}>Login{login ?
                        <i className="fa fa-spinner fa-spin"></i> : ""}
                    </button>
                </form>
                : <div>
                    <form onSubmit={handleLookup}>
                        <img src={ftmlogo} alt="Fresh Thyme Market logo" width="30%"
                             height="auto"/>

                        <Alert alert={alert}/>
                        <h4 id="sub_head" style={{fontFamily: "A Love of Thunder", color: "#007935"}}>Find account</h4>
                        <div className="col-12">
                            <div className="form-group">
                                <Select style={{border: "2px solid #000", borderRadius: "0"}}
                                        onChange={values => handleFieldChange(values)}
                                        options={lookupFields}
                                        dropdownHeight="350px"
                                        labelField="label"
                                        valueField="id"
                                        name="fieldType"
                                        placeholder="Select field name *"
                                />
                                <div className="text-danger">{lookupError.fieldType}</div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="form-group">
                                <input
                                    type="text"
                                    name="searchValue"
                                    onChange={handleSearchValueChange}
                                    className="form-control"
                                    placeholder="Enter Email, loyaltyID or phoneNo *"
                                    id="searchValue"
                                />
                                <div className="text-danger">{lookupError.searchValue}</div>
                            </div>
                        </div>
                        <button id="sub_btn" type="submit"
                                style={{backgroundColor: "#007935", height: "35px", fontSize: "1.2em"}}
                                disabled={lookingUp}>Look-Up{lookingUp ?
                            <i className="fa fa-spinner fa-spin"></i> : ""}
                        </button>
                    </form>
                    {showSummary && <SummaryModal {...showDetails}/>}
                    <div className="container mt-3">
                        {/* Tabs */}
                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button
                                    className={`nav-link ${activeTab === 'table' ? 'active' : ''}`}
                                    id="table-tab"
                                    data-bs-toggle="tab"
                                    type="button"
                                    role="tab"
                                    aria-controls="table"
                                    aria-selected={activeTab === 'table'}
                                    onClick={() => handleTabClick('table')}
                                >
                                    Data Details
                                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button
                                    className={`nav-link ${activeTab === 'form' ? 'active' : ''}`}
                                    id="form-tab"
                                    data-bs-toggle="tab"
                                    type="button"
                                    role="tab"
                                    aria-controls="form"
                                    aria-selected={activeTab === 'form'}
                                    onClick={() => handleTabClick('form')}
                                >
                                    Update Account
                                </button>
                            </li>
                            {user?.email && lookUpResponse?.meta?.hasDeleteAccess &&
                                <li className="nav-item" role="presentation">
                                    <button
                                        className={`nav-link ${activeTab === 'delete-form' ? 'active' : ''}`}
                                        id="delete-form-tab"
                                        data-bs-toggle="tab"
                                        type="button"
                                        role="tab"
                                        aria-controls="form"
                                        aria-selected={activeTab === 'form'}
                                        onClick={() => handleTabClick('delete-form')}
                                    >
                                        Delete Account
                                    </button>
                                </li>}
                        </ul>

                        {/* Tab Content */}
                        <div className="tab-content mt-3">
                            <div
                                className={`tab-pane fade ${activeTab === 'table' ? 'show active' : ''}`}
                                id="table"
                                role="tabpanel"
                                aria-labelledby="table-tab"
                            >
                                {lookUpResponse && (
                                    <table className="table table-hover table-bordered">
                                        <thead className="thead-dark">
                                        <tr>
                                            <th scope="col">Source</th>
                                            <th scope="col">Customer ID</th>
                                            <th scope="col">Email</th>
                                            <th scope="col">Phone Number</th>
                                            <th scope="col">Loyalty ID</th>
                                            <th scope="col">Selection</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr key="wynshop">
                                            <th scope="row">Wynshop</th>
                                            <td>{lookUpResponse?.meta?.wynshopCustomer?.id}</td>
                                            <td>{lookUpResponse?.meta?.wynshopCustomer?.email}</td>
                                            <td>{lookUpResponse?.meta?.wynshopCustomer?.phoneNo}</td>
                                            <td>{lookUpResponse?.meta?.wynshopCustomer?.loyaltyId}</td>
                                            <td>
                                                <button
                                                    id="sub_btn"
                                                    type="submit"
                                                    onClick={() => handleSetValues(lookUpResponse?.meta?.wynshopCustomer,"")}
                                                >
                                                    Set
                                                </button>
                                                {lookUpResponse?.meta?.wynshopCustomer?.id && lookUpResponse?.meta?.wynshopCustomer?.loyaltyId?.length > 11 && (
                                                    <button
                                                        id="sub_btn"
                                                        type="submit"
                                                        style={{backgroundColor: '#ff9800'}}
                                                        onClick={() => handleUpdateWynshopLoyaltyId(lookUpResponse?.meta?.wynshopCustomer?.id)}
                                                    >
                                                        Fix Loyalty ID
                                                    </button>
                                                )}
                                            </td>
                                        </tr>
                                        <tr key="inmar">
                                            <th scope="row">Inmar</th>
                                            <td>{lookUpResponse?.meta?.inmarCustomer?.id}</td>
                                            <td>{lookUpResponse?.meta?.inmarCustomer?.email}</td>
                                            <td>{lookUpResponse?.meta?.inmarCustomer?.phoneNo}</td>
                                            <td>{lookUpResponse?.meta?.inmarCustomer?.loyaltyId}</td>
                                            <td>
                                                <button
                                                    id="sub_btn"
                                                    type="submit"
                                                    onClick={() => handleSetValues(lookUpResponse?.meta?.inmarCustomer,"")}
                                                >
                                                    Set
                                                </button>
                                            </td>
                                        </tr>
                                        {lookUpResponse?.data?.map(customer => (
                                            <tr key={customer?.id}>
                                                <th scope="row">Cosmos</th>
                                                <td>{customer?.id}</td>
                                                <td>{customer?.email}</td>
                                                <td>{customer?.phoneNo}</td>
                                                <td>{customer?.loyaltyId}</td>
                                                <td>
                                                    <button
                                                        id="sub_btn"
                                                        type="submit"
                                                        onClick={() => handleSetValues(customer,"")}
                                                    >
                                                        Set
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                )}
                            </div>
                            <div
                                className={`tab-pane fade ${activeTab === 'form' ? 'show active' : ''}`}
                                id="form"
                                role="tabpanel"
                                aria-labelledby="form-tab"
                            >
                                <form onSubmit={handleSubmit}>
                                    <h4 id="sub_head" style={{fontFamily: 'A Love of Thunder', color: '#007935'}}>
                                        Update account
                                    </h4>
                                    <h6 id="note">All fields required *</h6>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    name="customerId"
                                                    onChange={handleChange}
                                                    className="form-control"
                                                    placeholder="Customer ID *"
                                                    id="customerId"
                                                    value={user?.customerId}
                                                    disabled
                                                />
                                                <div className="text-danger">{error.customerId}</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    name="firstName"
                                                    onChange={handleChange}
                                                    className="form-control"
                                                    placeholder="First Name *"
                                                    id="firstName"
                                                    value={user?.firstName}
                                                />
                                                <div className="text-danger">{error.firstName}</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    name="lastName"
                                                    onChange={handleChange}
                                                    className="form-control"
                                                    placeholder="Last Name *"
                                                    id="lastName"
                                                    value={user?.lastName}
                                                />
                                                <div className="text-danger">{error.lastName}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            name="email"
                                            onChange={handleChange}
                                            className="form-control"
                                            placeholder="Email *"
                                            id="email"
                                            value={user?.email}
                                        />
                                        <div className="text-danger">{error.email}</div>
                                    </div>
                                    <div className="form-group">
                                        <input
                                            type="number"
                                            name="phoneNo"
                                            onChange={handleChange}
                                            className="form-control"
                                            placeholder="Primary Phone Number *"
                                            id="phoneNo"
                                            value={user?.phoneNo}
                                        />
                                        <div className="text-danger">{error.phoneNo}</div>
                                    </div>
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            name="birthday"
                                            className="form-control"
                                            placeholder="Date of Birth (MM/DD/YYYY)"
                                            id="birthday"
                                            value={user?.birthday}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        <div
                                            className={error.birthday ? "text-danger" : "text-success"}>{error.birthday ? error.birthday : error.birthdaySuccess}</div>
                                    </div>
                                    <div className="form-group">
                                        {stores.length > 0 && defaultStore ?
                                            <Select style={{border: "2px solid #000", borderRadius: "0"}}
                                                    onChange={values => handleStoreChange(values)}
                                                    options={stores}
                                                    dropdownHeight="350px"
                                                    labelField="ecomName"
                                                    valueField="ecomName"
                                                    name="storeLocation"
                                                    placeholder="Select Store *"
                                                    values={[defaultStore]}/>
                                            :
                                            <Select style={{border: "2px solid #000", borderRadius: "0"}}
                                                    onChange={values => handleStoreChange(values)}
                                                    options={stores}
                                                    dropdownHeight="350px"
                                                    labelField="ecomName"
                                                    valueField="ecomName"
                                                    name="storeLocation"
                                                    placeholder="Select Store *"
                                            />
                                        }
                                        <div className="text-danger">{error.storeLocation}</div>
                                    </div>
                                    <button
                                        id="sub_btn"
                                        type="submit"
                                        style={{backgroundColor: '#007935', height: '35px', fontSize: '1.2em'}}
                                        disabled={registering}
                                    >
                                        Update Account {registering ? <i className="fa fa-spinner fa-spin"></i> : ''}
                                    </button>
                                </form>
                            </div>

                            {user?.email && lookUpResponse?.meta?.hasDeleteAccess &&
                                <div
                                    className={`tab-pane fade ${activeTab === 'delete-form' ? 'show active' : ''}`}
                                    id="form"
                                    role="tabpanel"
                                    aria-labelledby="delete-form-tab">
                                    <form onClick={handleDeleteAccount}>
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                name="email"
                                                onChange={handleChange}
                                                className="form-control"
                                                placeholder="Email *"
                                                id="email"
                                                value={user?.email}
                                            />
                                            <div className="text-danger">{error.email}</div>
                                        </div>
                                        <button id="sub_btn"
                                                style={{
                                                    backgroundColor: "#ff0000",
                                                    height: "35px",
                                                    fontSize: "1.2em"
                                                }}
                                                disabled={registering}>Delete Account {registering ?
                                            <i className="fa fa-spinner fa-spin"></i> : ""}
                                        </button>
                                    </form>
                                </div>
                            }
                        </div>
                    </div>

                </div>
            }
        </div>
    );
}
