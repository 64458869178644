import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter, BrowserRouter as Router, Switch} from "react-router-dom";
import {AuthProvider} from "react-oidc-context"
import AuthorizedComponent from "./authorized-component";
import {UserManager, WebStorageStateStore} from 'oidc-client-ts';
import AuthorizedRoutes from "./authorized-routes";
import PublicRoutes from "./public-routes";

require('dotenv').config()
const oidcUrl = process.env.REACT_APP_STS_OIDC_AUTHORITY;
const responseMode = process.env.REACT_APP_STS_OIDC_RESPONSE_MODE;
const clientId = process.env.REACT_APP_STS_OIDC_CLIENT_ID;
const redirectUrl = process.env.REACT_APP_STS_OIDC_REDIRECT_UI;
const responseType = process.env.REACT_APP_STS_OIDC_RESPONSE_TYPE;
const scope = process.env.REACT_APP_STS_OIDC_SCOPE;

export const userManager = new UserManager({
    authority: oidcUrl,
    client_id: clientId,
    redirect_uri: redirectUrl,
    post_logout_redirect_uri: process.env.REACT_APP_STS_OIDC_REDIRECT_UI,
    userStore: new WebStorageStateStore({store: window.sessionStorage}),
    monitorSession: true,
    response_type: responseType,
    response_mode: responseMode,
    scope: scope
});

const onSigninCallback = () => {
    window.history.replaceState({}, document.title, window.location.pathname);
};

userManager.events.addUserSignedOut(() => {
    userManager.signoutRedirect();
});

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter basename="/">
            <Router>
                <Switch>
                    <AuthProvider userManager={userManager} onSigninCallback={onSigninCallback}>
                        <AuthorizedComponent>
                            <PublicRoutes privateRoute={false}/>
                            <AuthorizedRoutes privateRoute={true}/>
                        </AuthorizedComponent>
                    </AuthProvider>
                </Switch>
            </Router>
        </BrowserRouter>
    </React.StrictMode>
    , document.getElementById('root'));
