import React, {useState, useEffect} from 'react';
import {Registration} from "../components/register/Registration";
import {usePasswordValidation} from "../hooks/usePasswordValidation";
import {authService} from "../service/auth/auth.service";
import {getStoreLocationService} from "../service/storeLocator/storeLocator.service";
import {isValidDate} from "../utils/dateValidator";

const loginLink = process.env.REACT_APP_STS_REDIRECT_LOGIN_LINK;
const sessionStorageKey = process.env.REACT_APP_STS_SESSION_STORAGE_KEY;
const avoidEmailSuffix = process.env.REACT_APP_IGNORE_EMAIL_SUFFIX;

function Register(customProps) {
    if (sessionStorage.getItem(sessionStorageKey)) {
        sessionStorage.removeItem(sessionStorageKey);
    }
    const {alert, showAlert} = customProps;
    const queryString = window.location.search;
    const [registering, setRegistering] = useState(false);
    const [user, setUser] = useState(
        {
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            phoneNo: '',
            storeLocation: '',
            storeNumber: '',
            birthday: ''
        });
    const [error, setError] = useState({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        phoneNo: '',
        storeLocation: '',
        birthday: '',
        birthdaySuccess: ''

    });
    const [password, setPassword] = useState({
        firstPassword: ""
    });

    const [
        validLength,
        hasNumber,
        upperCase,
        lowerCase,
        specialChar
    ] = usePasswordValidation({
        firstPassword: password.firstPassword,
        secondPassword: password.firstPassword
    });
    const [stores, setStores] = useState([]);

    useEffect(() => {
        if (!stores.length) {
            getStoreLocationService.getStoreLocator()
                .then((data) => {
                    if (data) {
                        let stores = data.Stores;
                        stores = stores.filter((store) => {
                            if (store.ecomName) {
                                store.originalEcomName = store.ecomName;
                                return store;
                            }
                            return null;
                        })
                        setStores(stores);


                    }
                })
        }

    }, [stores.length]);


    const handleChange = (event) => {
        const key = event.target.name;
        let value = event.target.value;
        if (key === 'email') {
            if (value.endsWith(avoidEmailSuffix)) {
                setError(
                    {...error,"email": "Suffix \"@freshthyme.com\" is not allowed."}
                );
            }
            else{
                setError(
                    {...error,"email": ""}
                );
            }
        }
        user[key] = value;
        if (key === 'password') {
            setPassword({...password, firstPassword: value});
        }
        setUser(user);
    }
    const changeStoreLocation = (event, storeNumber) => {
        user.storeNumber = storeNumber;
        setUser(user);
        handleChange(event);
    }

    const reloadPage = (page) => {
        setTimeout(() => {
            if (page === 'login') {
                window.location.href = loginLink;
            } else {
                window.location.href = `/${page}`;
            }
        }, 4000)
    }
    const handleSubmit = (event) => {
        event.preventDefault();
        if (validate()) {
            setRegistering(true);
            authService.registration(user).then(data => {
                    if (data?.message === "Success") {
                        showAlert('Registration successful!', "success");
                        reloadPage('login');
                    } else if (data?.message) {
                        showAlert(data.message, "success");
                        // reloadPage('register');
                    } else if (data?.error) {
                        showAlert(data.error, "danger");
                        // reloadPage('register');
                    } else {
                        showAlert("Unable to register at this time", "danger")
                        // reloadPage('register');
                    }
                    setRegistering(false);
                }, error => {
                    showAlert(error, "danger");
                }
            );
        }
    }

    const validate = () => {
        let pattern;
        let input = user;
        let errors = {};
        let isValid = true;

        if (!input["firstName"]) {
            isValid = false;
            errors["firstName"] = "Please enter your first name.";
        }
        if (!input["lastName"]) {
            isValid = false;
            errors["lastName"] = "Please enter your last name.";
        }

        if (input["email"]) {
            if (input["email"].endsWith(avoidEmailSuffix)) {
                isValid = false;
                errors["email"] = "Suffix \"@freshthyme.com\" is not allowed.";
            } else{
                setError(
                    {...error,"email": ""}
                );
            }
        }
        if (!input["email"]) {
            isValid = false;
            errors["email"] = "Please enter your email Address.";
        }


        if (typeof input["email"] !== "undefined") {

            pattern = new RegExp(/^(("[\w-\s]+")|([+\w-]+(?:\.[+\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            // pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

            if (!pattern.test(input["email"])) {
                isValid = false;
                errors["email"] = "Please enter a valid email address.";
            }
        }

        if (!input["storeLocation"]) {
            isValid = false;
            errors["storeLocation"] = "Please select store nearby.";
        }

        if (!validLength || !hasNumber || !upperCase || !lowerCase || !specialChar) {
            isValid = false;
            errors["password"] = "Please enter a valid password.";
        }

        if (!input["phoneNo"]) {
            isValid = false;
            errors["phoneNo"] = "Please enter your phone number.";
        }

        if (input["birthday"]) {
            errors["birthday"] = isValidDate(input["birthday"]);
            if (errors["birthday"]) {
                isValid = false;
            } else {
                errors["birthdaySuccess"] = "Get a special reward for your birthday every year";
            }
        }

        if (typeof input["phoneNo"] !== "undefined") {
            pattern = new RegExp(/^[0-9\b]+$/);

            if (!pattern.test(input["phoneNo"])) {
                isValid = false;
                errors["phoneNo"] = "Please enter only numbers.";
            } else if (input["phoneNo"].length !== 10) {
                isValid = false;
                errors["phoneNo"] = "Please enter a valid phone number.";
            }
        }
        setError(
            errors
        );
        return isValid;
    }

    const props = {
        handleSubmit,
        handleChange,
        error,
        validLength,
        upperCase,
        lowerCase,
        specialChar,
        hasNumber,
        stores, queryString,
        changeStoreLocation,
        registering, alert
    };
    return <Registration {...props}/>
}

export default Register;
