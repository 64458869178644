import React, {useState, useEffect} from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import {userProfileService} from "../service/userProfile/userProfile.service";
import {usePasswordValidation} from "../hooks/usePasswordValidation";
import {Profile} from "../components/profile/Profile";
import {ProfileModal} from "../components/modals/ProfileModal";
import {isValidDate} from "../utils/dateValidator";

const webURL = window.location.origin;
export const ProfilePage = (props) => {

    const {alert, showAlert, updateProps} = props;
    const [userEmail, setUserEmail] = useState(null);
    const [show, setShow] = useState(false);
    const handleClose = () => {
        setShow(false);
    };
    const handleShow = () => {
        setShow(true);
    }

    const [passwordCheck, setPasswordCheck] = useState(false);
    const passwordHandleClose = () => setPasswordCheck(false);
    const passwordHandleShow = () => {
        setPasswordCheck(true);
    };

    const [userProfile, setUserProfile] = useState(
        null
    );
    const [isChanged, setIsChanged] = useState(true);

    const [emailDetails, setEmailDetails] = useState(
        {email: props?.email}
    );
    const [password, setPassword] = useState({
        newPassword: "", secondPassword: "", oldPassword: ""
    });

    const [passwordDetails, setPasswordDetails] = useState(
        {email: ""}
    );

    const [error, setError] = useState({
        firstName: '',
        lastName: '',
        phoneNo: '',
        birthday: '',
    });
    const [showProfilePopUp, setShowProfilePopUp] = useState(false);

    const handleCloseProfilePopUp = () => {
        setShowProfilePopUp(false);
    };
    const [emailError, setEmailError] = useState({
        email: '',
    });
    const [
        validLength,
        hasNumber,
        upperCase,
        lowerCase,
        specialChar,
        passwordMatch
    ] = usePasswordValidation({
        firstPassword: password.newPassword,
        secondPassword: password.secondPassword
    });
    useEffect(() => {
        setUserEmail(props?.email);
    }, [props.email]);


    useEffect(() => {
        if (Object.keys(props).length > 3) {
            if (!props?.firstName || !props?.lastName || !props?.phoneNo) {
                setShowProfilePopUp(true);
            } else {
                let namePattern = new RegExp(/^[a-zA-Z ]*$/);
                if (!namePattern.test(props?.firstName) || !namePattern.test(props?.lastName)) {
                    setShowProfilePopUp(true);
                } else {
                    setShowProfilePopUp(false);
                }
            }
            setUserProfile({
                firstName: props.firstName,
                lastName: props.lastName,
                email: props.email,
                phoneNo: props.phoneNo,
                birthday: props.birthday,
            });

            setEmailDetails({
                oldEmail: props.email,
                newEmail: emailDetails?.newEmail
            });
        }

    }, [props, emailDetails.newEmail]);


    const handleChange = (event) => {
        userProfile[event.target.name] = event.target.value;
        setIsChanged(false);
        setUserProfile(userProfile);
    }

    const emailHandleChange = (event) => {
        emailDetails[event.target.name] = event.target.value;
        setEmailDetails(emailDetails);
    }

    const emailValidation = () => {
        let pattern;
        let input = emailDetails;
        let errors = {};
        let isValid = true;
        if (!input["newEmail"]) {
            isValid = false;
            errors["email"] = "Please enter your email Address.";
        }
        if (typeof input["newEmail"] !== "undefined") {
            pattern = new RegExp(/^(("[\w-\s]+")|([+\w-]+(?:\.[+\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(input["newEmail"])) {
                isValid = false;
                errors["email"] = "Please enter a valid email address.";
            }
        }

        setEmailError(
            errors
        );
        return isValid;
    }

    const validate = () => {
        let pattern;
        let input = userProfile;
        let errors = {};
        let isValid = true;

        if (!input["firstName"]) {
            isValid = false;
            errors["firstName"] = "Please enter valid first name.";
        }
        if (!input["lastName"]) {
            isValid = false;
            errors["lastName"] = "Please enter valid last name.";
        }

        if (input["firstName"] || input["lastName"]) {
            let namePattern = new RegExp(/^[a-zA-Z ]*$/);
            if (!namePattern.test(input["firstName"])) {
                isValid = false;
                errors["firstName"] = "Please enter valid first name.";
            }
            if (!namePattern.test(input["lastName"])) {
                isValid = false;
                errors["lastName"] = "Please enter valid last name.";
            }
        }
        if (input["birthday"]) {
            errors["birthday"] = isValidDate(input["birthday"]);
            if (errors["birthday"]) {
                isValid = false;
            }
        }

        if (!input["phoneNo"]) {
            isValid = false;
            errors["phoneNo"] = "Please enter your phone number.";
        }

        if (typeof input["phoneNo"] !== "undefined") {
            pattern = new RegExp(/^[0-9\b]+$/);

            if (!pattern.test(input["phoneNo"])) {
                isValid = false;
                errors["phoneNo"] = "Please enter only numbers.";
            } else if (input["phoneNo"].length !== 10) {
                isValid = false;
                errors["phoneNo"] = "Please enter a valid phone number.";
            }
        }
        setError(
            errors
        );
        return isValid;
    }

    const handleSubmit = (event) => {
        // event.preventDefault();
        if (validate()) {
            return userProfileService.updateProfile(userProfile, props?.email).then(data => {
                setIsChanged(true);
                if (data === null) {
                    showAlert("Sorry, We are unable to update your profile at this time", "danger");
                } else if (data?.message === 'Customer profile edited successfully' || data?.message === 'ok') {
                    if (showProfilePopUp) {
                        handleCloseProfilePopUp();
                    }
                    updateProps(userProfile)
                    showAlert("Profile updated successfully", "success");
                } else if (data?.message === 'Sorry, this phone number is already registered') {
                    showAlert("Sorry, this phone number is already registered", "warning");
                }
                // window.location.reload();
            });
        }
    }

    const handleDelete = () => {
        const userConfirmed = window.confirm("Are you sure you want to proceed?");
        if (userConfirmed) {
            userProfileService.deleteProfile().then(data => {
                setIsChanged(true);
                if (data === null) {
                    showAlert("Sorry, We are unable to delete your profile at this time", "danger");
                } else if (data?.message === 'Successfully deleted your account' || data?.message === 'ok') {
                    showAlert("Successfully deleted your account", "success");
                    window.sessionStorage.clear();
                    window.location.href=process.env.REACT_APP_REDIRECT_URI;
                }
            });
        }
    }

    const emailSubmit = (event) => {
        event.preventDefault();

        if (emailValidation()) {
            return userProfileService.updateEmail(emailDetails).then(data => {
                if (data) {
                    if (data?.message === 'Sorry, this email is already registered') {

                        setEmailDetails({
                            oldEmail: props.email,
                            newEmail: emailDetails.newEmail
                        });
                        showAlert("Sorry, this email is already registered", "danger");

                    } else {
                        showAlert("Updated email successfully", "success");
                        localStorage.clear();
                        handleClose();
                        setUserEmail(emailDetails?.newEmail);
                        setUserProfile({...userProfile, email: emailDetails.newEmail});
                    }
                } else {
                    showAlert("Sorry, We are unable to update your email at this time", "warning");
                }
                // window.location.href = `${webURL}/account-settings`;

            });
        }

    }

    const passwordSubmit = (event) => {
        event.preventDefault();

        if (validLength && hasNumber && upperCase && lowerCase && specialChar && passwordMatch) {
            return userProfileService.updatePassword(passwordDetails)
                .then((data) => {
                    if (data.message === "Password is changed successfully") {
                        showAlert("your password is successfully changed. Signing you out", "success");
                        localStorage.clear();
                        window.location.href = `${webURL}`;
                    } else {
                        showAlert('error', "danger");
                    }
                })
        }


    }

    const passwordHandleChange = (event) => {

        password[event.target.name] = event.target.value;
        if (event.target.name === 'oldPassword') {
            setPassword({...password, oldPassword: event.target.value});
        } else if (event.target.name === 'secondPassword') {
            setPassword({...password, secondPassword: event.target.value});
        } else if (event.target.name === 'newPassword') {
            setPassword({...password, newPassword: event.target.value});
        }
        setPasswordDetails({oldPassword: password.oldPassword, newPassword: password.newPassword, email: props.email})
    }

    const prof = {
        handleSubmit,
        handleDelete,
        handleChange,
        validLength,
        upperCase,
        lowerCase,
        specialChar,
        hasNumber,
        passwordHandleChange,
        passwordSubmit,
        emailSubmit,
        show,
        handleClose,
        handleShow,
        passwordCheck,
        passwordHandleClose,
        passwordHandleShow, emailHandleChange,
        passwordMatch,
        password, userEmail, isChanged, error, emailError, alert, userProfile

    };
    const profileModalDetails = {
        handleSubmit,
        handleChange,
        handleCloseProfilePopUp,
        userProfile, isChanged, error,
        showProfilePopUp, alert
    };

    return <div>
        {showProfilePopUp ? <ProfileModal {...profileModalDetails}/> : ''}
        <Profile {...prof}/>
    </div>
}

