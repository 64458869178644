import React, {useState} from "react"
import './App.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import "./helpers/spinner.css";
import {AccountSettings} from "./components/accountSettings/AccountSettings";
import {Route} from "react-router-dom";

function AuthorizedRoutes() {

    const [alert, setAlert] = useState(null);

    const showAlert = (message, type) => {
        setAlert(({
            msg: message,
            type: type
        }))
        setTimeout(() => {
            setAlert(null);
        }, 4000)
    }

    const props = {alert, showAlert};

    return (

        <>
            <Route path={"/account-settings"}
                   render={() => <AccountSettings {...props} />}/>
        </>
    )
}

export default AuthorizedRoutes;
